import style from './AcessoRapido.module.css'

function AcessoRapido(){
    return(
        <>
            <div className={style.divQuickAccess}>
                <div className={style.divIntoQuickAcess}>
                    <div>Saldo total: R$ <b>0,00</b></div>
                </div>

                <div className={style.divIntoQuickAcess}>
                    <button className={`${style.btn} btn-sm`}>Nova Receita</button> 
                    <button className={`${style.btn} btn-sm`}>Nova Despesa</button>
                    {/* <button className={`${style.btn}`}>Transferir</button> */}
                </div>
                
            </div> 
        </>
    )
}   

export default AcessoRapido