import style from './MenuProfile.module.css'

import { useState } from 'react'

function MenuProfile(){

    const [avatar, setAvatar] = useState('avatar-cristian.jpg');
    const [nome, setNome] = useState('Cristian');
    
    return (
        <div className={style.containerpmp}>
            <div className={style.title}>
                <h1 className={style.hello}>Olá, {nome}</h1>
            </div>
            <div className={style.divNotifications}>
                <i className="fi fi-rs-bell"></i>
            </div>
            <div className={style.divProfile}>
                <img src={avatar} className={style.avatarProfile} />
                <div className={style.nameProfile}>
                    Cristian Barbosa Andrisen
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}

export default MenuProfile;