import style from "./Lancamentos.module.css";
import { useEffect, useState } from "react";
import Modal from "../modal/Modal";
import { Container, Row, Col } from "react-grid-system";

export default function Lancamentos(){
    const [dadosLancamentos, setDadosLancamentos] = useState([
        {id:1, descricao: "SimplesTI", tipo:"R", tipoConta: "Salário", valor: 4300},
        {id:2, descricao: "Sofá", tipo:"D", tipoConta: "Pagamentos", valor:180},
        {id:3, descricao: "Investimentos", tipo:"R", tipoConta: "Inv.", valor:20},
    ]);

    const [modalLancamentos, setModalLancamentos] = useState(false);
    const [tipo, setTipo] = useState("");
    const [tipoConta, setTipoConta] = useState("");
    const [descricao, setDescricao] = useState("");
    const [valor, setValor] = useState(0);
    const [subtotal, setSubtotal] = useState(0);

    const CalcSubtotal = () => {
        var total = dadosLancamentos.reduce(getTotal, 0);
        function getTotal(total, item) {
            if (item.tipo === "R"){
                return total + (item.valor);
            } else {
                return  total - (item.valor);
        }
        }
        setSubtotal(total)
        
    }

    const LimparItensLancamento = () => {
        setTipo("");
        setTipoConta("");
        setDescricao("");
        setValor("");
    }

    const Lancamentos = () => {
        setModalLancamentos(false);
        if (!descricao || !tipo || !tipoConta || !valor) return
        setDadosLancamentos([...dadosLancamentos, {id:4, descricao: descricao, tipo:tipo, tipoConta: tipoConta, valor:parseFloat(valor)}]);
    }

    useEffect(()=>{
        console.log(dadosLancamentos)
        CalcSubtotal();
    },[dadosLancamentos])


    return(
        <>
            <div className={style.containerp}>
                <div className={style.head}>
                    Lançamentos &nbsp;&nbsp;&nbsp;<button className={style.btnHidden} onClick={()=>setModalLancamentos(true)}><i className={`fi fi-rs-add ${style.iconAdd}`} ></i></button>
                </div>
                {/* <div className={style.divFilter}>
                    <input type="search" className={`${style.filter} ${style.filterMain}`} placeholder="Filtrar por..."/>
                    <input type="search" className={style.filter} placeholder="Filtro 2"/>
                    <input type="search" className={style.filter} placeholder="Filtro 3"/>
                    <input type="search" className={style.filter} placeholder="Filtro 4"/>
                    <input type="search" className={style.filter} placeholder="Filtro 5"/>
                </div> */}
                

                { modalLancamentos && (
            
                   <Modal modalHide={()=>setModalLancamentos(false)} 
                    modalSave={()=>{
                        LimparItensLancamento();
                        Lancamentos();
                    }} 
                    title="Novo Lançamento"
                    content={
                    <>
                        <Row>
                            <Col sm={3}>
                                <label>Receita ou Despesa?</label>
                                <br/>
                                <select className="form-control form-control-sm" onChange={(e)=>{setTipo(e.target.value)}}>
                                    <option selected disabled>Selecione um item...</option>
                                    <option value="R">Receita</option>
                                    <option value="D">Despesa</option>
                                </select>
                            </Col>

                            <Col sm={3}>
                                <label>Descrição</label>
                                <br/>
                                <input type="text" className="form-control form-control-sm"  onChange={(e)=>{setDescricao(e.target.value)}}/>
                            </Col>

                            <Col sm={3}>
                                <label>Tipo de Conta</label>
                                <br/>
                                <select  className="form-control form-control-sm"  onChange={(e)=>{setTipoConta(e.target.value)}}>
                                    <option selected disabled>Selecione um item...</option>
                                    <option value="Salário">Salário</option>
                                    <option value="Pagamentos">Pagamentos</option>
                                    <option value="Inv.">Inv.</option>
                                </select>
                            </Col>

                            <Col sm={2.88}>
                                <label >Valor</label>
                                <br/>
                                <input type="text"  className="form-control form-control-sm"  onChange={(e)=>{setValor(e.target.value)}}/>
                            </Col>
                        </Row>
                    </>
                   }
                   />
                )}


                <br/>
                <br/>

                <div className={style.rowReleases}>
                    <div className={`${style.itemRelease} ${style.itemDescription}`}><b>Descrição</b></div>
                    <div className={`${style.itemRelease} ${style.itemTypeAccount}`}><b>Categoria</b></div>
                    <div className={`${style.itemRelease} ${style.itemTypeAccount}`}><b>Tipo de Conta</b></div>
                    <div className={`${style.itemRelease} ${style.itemValue}`}><b>Valor</b></div>
                </div>

                { !dadosLancamentos ? (
                    <div className={style.movements}>
                        <i class="fi fi-rs-info"></i>
                        <p className={style.titleMovements}>Nenhuma movimentação no período.</p>
                    </div>
                ) 
                : 
                (
                    dadosLancamentos.map((item)=>{
                    return(<div key={item.id}>
                        <div className={style.rowReleases}>
                            <div className={`${style.itemRelease} ${style.itemDescription}`}>{item.descricao}</div>
                            <div className={`${style.itemRelease} ${style.itemTypeAccount}`}>
                                <strong className={item.tipo === "R" ? "colorGreen" : "colorRed"}>{item.tipo === "R" ? "Receita" : "Despesa"}</strong>
                            </div>
                            <div className={`${style.itemRelease} ${style.itemTypeAccount}`}>{item.tipoConta}</div>
                            <div className={`${style.itemRelease} ${style.itemValue}`}>R$ {item.valor}</div>
                        </div>
                    </div>)
                    })
                )
                }
                

                <div className={style.rowReleases}>
                    <div className={`${style.itemRelease} ${style.itemDescription}`}></div>
                    <div className={`${style.itemRelease} ${style.itemTypeAccount}`}></div>
                    <div className={`${style.itemRelease} ${style.itemValue}`}><b>Subtotal:  R$ {subtotal}</b></div>
                </div>
            </div>
        </>
    )
}

