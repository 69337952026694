import style from './Content.module.css'

function Content({children}){
    return(
        <section className={style.main}>
            {children}
        </section>
    )
}

export default Content