import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Content from './components/content/Content';
import Menu from './components/menu/Menu'
import MenuProfile from './components/menu-profile/MenuProfile';
import AcessoRapido from './components/acesso-rapido/AcessoRapido';
import Balanco from './components/balanco/Balanco';
import Lancamentos from './components/lancamentos/Lancamentos';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className='navbarp'> <Menu /> </div>
        <div className='containerp'>
            <MenuProfile />
            <Routes>
              <Route exact path="/" element={
                <>
                  <Content> <AcessoRapido /> </Content>
                  {/* <Content> <MinhasContas /> </Content> */}
                  <Content> <Balanco title='Balanço Mês'/> </Content>
                </>
              }>
              </Route>

              <Route path="/lancamentos" element={
                <>
                  <Content> <Lancamentos/> </Content>
                </>
              }>
              </Route>

              <Route path="/receitas" element={
                <>
                  <Content>  </Content>
                </>
              }>
              </Route>

              <Route path="/despesas" element={
                <>
                  <Content>  </Content>
                </>
              }>
              </Route>

            </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
