import style from './Menu.module.css'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useState } from 'react';

function HeaderView() {
  let location = useLocation();
  return location.pathname
}

function Menu(){
    const [page, setPage] = useState(HeaderView());
    return(
        <div className={style.navbarpm}>
            <div className={`${style.logop}`}>F i n</div> 
            <ul>
                <Link to="/"  onClick={()=>setPage(HeaderView())} className={`${style.menuItem} ${page == "/" ? style.active : ""}`}>
                    <i className="fi fi-rs-home"></i> &nbsp;&nbsp; Início
                </Link>

                <Link to="/lancamentos" onClick={()=>setPage(HeaderView())} className={`${style.menuItem}  ${page == "/lancamentos" ? style.active : ""}`}>
                    <i className="fi fi-rs-dollar"></i> &nbsp;&nbsp; Lançamentos
                </Link>

                <Link to="/receitas" onClick={()=>setPage(HeaderView())} className={`${style.menuItem}  ${page == "/receitas" ? style.active : ""}`}>
                    <i className="fi fi-rs-add"></i> &nbsp;&nbsp; Receitas
                </Link>

                <Link to="/despesas" onClick={()=>setPage(HeaderView())} className={`${style.menuItem}  ${page == "/despesas" ? style.active : ""}`}>
                    <i className="fi fi-rs-minus-small"></i> &nbsp;&nbsp; Despesas
                </Link>
               

                {/* <Link to="/contas" className={style.menuItem}>
                    <i className="fi fi-rs-layers"></i> &nbsp;&nbsp; Plano de contas
                </Link> */}

                {/* <Link to="/transacoes" className={style.menuItem}>
                    <i className="fi fi-rs-exchange"></i> &nbsp;&nbsp; Transações
                </Link> */}

                {/* <Link to="/" className={style.menuItem}>
                    <i className="fi fi-rs-chat-arrow-grow"></i> &nbsp;&nbsp; Relatórios
                </Link> */}

                <Link to="/" className={`${style.menuItem} ${style.logout}`}>
                    <i className="fi fi-rs-sign-out"></i> &nbsp;&nbsp; Logout
                </Link>
               
            </ul>
        </div>
    )
}

export default Menu;