import style from './Balanco.module.css'

function Balanco({title}){
    return (
        <>
            <h1 className={style.title}>{title}</h1>
            <div className={style.main}>
                <div>
                    <div className={`${style.percentBalance} ${style.recipe}`}>0,00</div>
                    <div className={`${style.percentBalance} ${style.expense}`}>0,00</div>
                </div>
                <div className={style.result}>
                    Resultado 
                    <b>0,00</b>
                </div>
            </div>
        </>
    )
}

export default Balanco