import style from './Modal.module.css'

export default function Modal({modalHide, content, modalSave, title}) {
  return (
    <>
    <div className={style.modalBackdrop}></div>
    <div className={style.outModal}>
      <div className={style.containerModalMd}>
        <div className={style.modalTitle}>
            <h5>{title}</h5>
            <small className={style.btnClose} onClick={modalHide}>x</small>
        </div>
        <div className={style.contentModal}>
        {content}
        </div>
        <div className={style.footerModal}>
          <button className={`${style.btnSave} btn-sm`} onClick={modalSave}>Salvar</button>
          <button className={`${style.btnExit} btn-sm`} onClick={modalHide}>Sair</button>
        </div>
      </div>
    </div>
    </>
  );
}
